import { useMerchantCreateStore, useUserTeamsStore } from 'UseMerchantStore'
import clsx from 'clsx'
import LocationAdditional from 'components/LocationsAdditional'
import { ButtonIcon } from 'components/button/ButtonIcon'
import { showDialog } from 'components/common/Dialog'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import CustomInput from 'components/input/CustomInput'
import { TwLableForm, TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { LOCATION } from 'constants/common'
import { PATH } from 'constants/routes'
import { useMerchantData } from 'hooks/useMerchantData'
import { ReactComponent as TrashSVG } from 'icons/trash.svg'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useProgramStore } from 'stores/programStore'
import { IAddress } from 'types/program.module'
import NotificationPanel from 'ui/NotificationPanel'
import {
  CardConfig,
  MerchantEntry,
  PointCardConfig,
  ScratchyCardConfig,
  TokenCardConfig,
} from '../../../functions/src/data/common'
import ProgramCard from './ProgramCard'
import ColorPickerPopover from './components/ColorPickerPopover'
import { cleanObject } from 'util/cleanObject'

export interface IProgramForm extends FieldValues {
  name: string
  description?: string | null
  location: string
  primaryColor: string | null
  secondaryColor: string | null
  logo?: FileList
  reward: number
  isTest?: boolean
}

const ProgramCreate: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const navigate = useNavigate()
  const { id: merchantIdParams } = useParams()
  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    getValues,
    setValue,
    setError,
    resetField,
    reset,
  } = useForm<IProgramForm>()
  const queryParams = new URLSearchParams(window.location.search);
  const newUrlReplaced = `${window.location.pathname}?isLaunch=true`;
  const isLaunchParam = queryParams.get('isLaunch');

  const [locations, setLocations] = useState<IAddress[]>([])
  const [isError, setIsError] = useState<boolean>(false)
  const [initialResetDone, setInitialResetDone] = useState<boolean>(false)
  const [trialValue, setTrialValue] = useState<number>(0)
  const [logoEdit, setLogoEdit] = useState<string | null>(null) // Provide a default value of an empty string
  const [clearImgToData, setClearImgToData] = useState<boolean>(false)

  const firebase = useFirebase(firebaseConfig)
  const { setProgramBody, programBody, clearFormData } = useProgramStore()
  const { isCreating } = useMerchantCreateStore(firebase.firebaseDb)
  const { user } = useUserNullable({ auth: firebase.auth })
  const userTeams = useUserTeamsStore(user, firebase.firebaseDb)
  const merchantId = useMemo(() => {
    if (userTeams.type !== 'SUCCESS' || !userTeams.teams) {
      return undefined;
    }

    const merchantIdMatch = Object.keys(userTeams.teams.permissions).find(
      (merchantId) => merchantId === merchantIdParams,
    );

    return merchantIdMatch ?? Object.keys(userTeams.teams.permissions)[0] ?? undefined;
  }, [merchantIdParams, userTeams.teams, userTeams.type]);



  const merchantData = useMerchantData(merchantId)

  const configId = merchantData?.merchant.defaultCardConfigId

  const tokenCardConfig = useMemo(() => {
    if (merchantData?.merchant.cardConfigs) {
      const data = merchantData.merchant

      return Object.values(data.cardConfigs ?? {})
        .mapNotNull((cardConfig) =>
          cardConfig.cardConfigType === 'tokens' ? cardConfig : undefined,
        )
        .firstOrNull()
    }
    return undefined
  }, [merchantData?.merchant])

  const cardTemplate = tokenCardConfig?.templates?.[tokenCardConfig.defaultTemplateId]

  const handleSubmitTest = async () => {
    setValue('isTest', tokenCardConfig?.isLaunched ?? true)
    await onSubmit()
  }

  const onSubmit = handleSubmit(async (value) => {
    if (locations.length === 0) {
      setError('location', { type: 'value', message: 'Store location must be added' })
      return
    }

    const paidLocations = locations.length === 1 ? locations : locations.filter((item) => item.isPaid)

    const newProgram: CardConfig = cleanObject({
      cardConfigType: 'tokens',
      locations: paidLocations,
      isLaunched: isLaunchParam ? false : tokenCardConfig?.isLaunched || value.isTest !== undefined && !value.isTest,
      templates: {
        default: {
          templateType: 'tokens',
          offers: [{ cost: value.reward, label: 'coffee' }],
          trial: isEdit ? trialValue : 0,
          target: cardTemplate?.target ? cardTemplate?.target : 10,
          lineMax: value.reward || programBody.reward || null,
          primaryColor: value.primaryColor || null,
          secondaryColor: value.secondaryColor || null,
        },
      },
      defaultTemplateId: 'default',
      description: value.description || programBody.description || null,
      logo: programBody.logo || logoEdit,
      title: value.name || programBody.name || null,
    }) satisfies TokenCardConfig | PointCardConfig | ScratchyCardConfig

    try {
      const merchantRef = firebase.firebaseDb.getRef<MerchantEntry>(`/merchants/${merchantId}`);
      if (!configId) {
        await merchantRef.update({
          defaultCardConfigId: 'tokens',
        });
      }

      const finalConfigId = configId ?? 'tokens'
      await merchantRef
        .childFromKey('cardConfigs')
        .update({ [finalConfigId]: newProgram }).then(() => {
          isLaunchParam && merchantId ? navigate(PATH.PROGRAM_PAYMENT.replace(':merchantId', merchantId)) : navigate(merchantId ? PATH.MERCHANT_DASHBOARD.replace(':id', merchantId) : PATH.HOME)
        });

    } catch (error) {
      console.error('Error saving merchant data', error);
    }
  })

  const handleInputChange = (name: keyof IProgramForm, value: any) => {
    if (name !== 'location') {
      setProgramBody({ ...programBody, [name]: value })
    }
  }

  const handleClearAddress = async (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e.preventDefault()
    if (locations.length > 0) {
      const paidLocations = locations.find((item) => item.id === id && item.isPaid)
      if (paidLocations) {
        showDialog({
          title: 'Location is paid',
          children: 'This location is paid, you cannot remove it!',
          positiveButtonProps: {
            text: 'OK',
            onClicked: () => {
              return true
            }
          },
        })
        return
      } else {
        const locationsFilter = locations.filter((item) => item.id !== id)
        const isUnpadLocations = locationsFilter.filter((item) => item.id === id && !item.isPaid)

        if (isUnpadLocations && isUnpadLocations.length <= 0) {
          setIsError(false)
        }
        setLocations(locationsFilter)
      }
    }
  }

  const handleAddLocation = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      const newLocation = getValues('location')
      const isExisted =
        newLocation &&
        locations.find(
          (item) => item.address.toLocaleLowerCase() === newLocation.toLocaleLowerCase(),
        )

      if (isExisted) {
        setError('location', { type: 'value', message: 'Store location is already existed!' })
        return
      }
      if (locations.length >= LOCATION.FREE_LOCATION_NUMBER) {
        setIsError(true)
      } else {
        setIsError(false)
      }
      if (newLocation) {
        const lastItem = locations[locations.length - 1]
        const newId = lastItem ? lastItem.id + 1 : 1
        const mergeLocation = [
          ...locations,
          {
            id: newId,
            address: newLocation,
            isPaid: false,
            // isDefault: address.length === 0,
          },
        ] as IAddress[]
        setLocations(mergeLocation)
        setProgramBody({ ...programBody, location: mergeLocation })
        clearErrors('location')
        setValue('location', '')
      } else {
        setError('location', { type: 'value', message: 'Store location is empty!' })
      }
    },
    [locations, clearErrors, getValues, programBody, setError, setProgramBody, setValue],
  )


  useEffect(() => {
    // when the merchant had a program
    if (merchantData?.merchant && !initialResetDone) {
      const template = tokenCardConfig?.templates?.[tokenCardConfig.defaultTemplateId]

      if (!template) {
        return
      }
      reset({
        name: tokenCardConfig.title || undefined,
        reward: template.lineMax as number,
        description: tokenCardConfig.description || null,
        primaryColor: template.primaryColor || null,
        secondaryColor: template.secondaryColor || null,
      })
      const mappingLocations = tokenCardConfig.locations?.map((item, idx) => ({ ...item, isPaid: true })) ?? []

      setLogoEdit(merchantData.merchant.logo)
      setLocations(mappingLocations)
      setTrialValue(template.trial)
      setInitialResetDone(true)
    }
  }, [merchantData, reset, initialResetDone, tokenCardConfig])

  useEffect(() => {
    if (merchantId) {
      return () => {
        clearFormData();
      };
    }
  }, [clearFormData, merchantId]);


  useEffect(() => {
    const timeoutComplete = setTimeout(() => {
      if (!user) {
        showDialog({
          title: 'Oops!',
          children: 'You need to log in to register your business',
          positiveButtonProps: {
            text: 'Sign in',
            onClicked: () => {
              navigate(
                `/signin?redirect=${encodeURIComponent(location.pathname + location.search + location.hash)}`,
              )
              return true
            },
          },
        })
      }
    }, 2000)

    return () => clearTimeout(timeoutComplete)
  }, [navigate, user])

  if ((isEdit && !merchantData) || !user) {
    return <SkeletonHome />
  }

  return (
    <section className='layout'>
      <TwMainGridWrapper className='layout h-[calc(100vh-5.5rem)] max-w-[31.25rem] rounded-md bg-light-mb-main lg:mt-6 lg:h-[80vh]
        lg:bg-white'>
        <NotificationPanel
          user={user}
          merchantId={merchantId}
          firebaseDb={firebase.firebaseDb}
          className='col-span-12 hidden md:col-span-6 lg:col-span-3 xl:block'
        />
        <ProgramCard
          className='col-span-12 lg:col-span-6 xl:col-span-4'
          cardConfig={tokenCardConfig}
          merchant={merchantData?.merchant}
          mode={merchantData?.merchant ? 'edit' : 'preview'}
        />
        <div
          className='animate-fade-right animate-once animate-ease-in relative box-content space-y-4
            overflow-y-auto rounded bg-white shadow-md col-span-12 lg:col-span-6 xl:col-span-5'>
          <div className='lg:sticky top-0 lg:z-10 flex items-start justify-between bg-white p-4'>
            <div className='space-y-2'>
              <h2 className='animate-fade-right animate-once animate-ease-in text-xl font-semibold'>
                {isEdit ? `Edit your program` : 'Create your program'}
              </h2>
              <p className='text-light-secondary'>
                Fill the below details to create your reward program
              </p>
            </div>
          </div>
          <div className='p-4'>
            <form
              onSubmit={onSubmit}
              className='pb-4'>
              <div className='lg:space-x-6'>
                <div className='col-span-2 space-y-6'>
                  <CustomInput
                    label='Name your program'
                    name='name'
                    type='text'
                    // maxLength={40}
                    error={errors.name}
                    onChangeCallback={(value) => handleInputChange('name', value)}
                    control={control}
                    rules={{ required: 'Name your program is required' }}
                  />

                  <CustomInput
                    label='Choose the number of purchases before your customer gets a reward'
                    name='reward'
                    type='range'
                    defaultValue={10}
                    max={20}
                    error={errors.reward}
                    onChangeCallback={(value) => handleInputChange('reward', value)}
                    control={control}
                    rules={{
                      required: 'Reward is required',
                      min: {
                        value: 1,
                        message: 'Reward must be greater than 0',
                      },
                      max: {
                        value: 20,
                        message: 'Reward must be less 20',
                      },
                    }}
                  />

                  <CustomInput
                    label="Detail your customer's reward"
                    name='description'
                    type='textarea'
                    control={control}
                    rules={{
                      required: "Detail your customer's reward is required",
                    }}
                    error={errors.description}
                    clearErrors={clearErrors}
                    placeholder='Eg. 1x Free Coffee or 20% off next purchase (over $20)'
                    onChangeCallback={(value) => handleInputChange('description', value)}
                    maxLength={50}
                  />

                  <div className='mb-4 space-y-2'>
                    <TwLableForm>Choose card background colour</TwLableForm>
                    <div className='flex gap-x-10'>
                      <ColorPickerPopover
                        control={control}
                        type='text'
                        defaultValue={'bg-slate-300'}
                        name='primaryColor'
                        onChangeCallback={(value) => handleInputChange('primaryColor', value)}
                        label='Background'
                      />
                      <ColorPickerPopover
                        control={control}
                        type='text'
                        defaultValue={'bg-slate-500'}
                        name='secondaryColor'
                        onChangeCallback={(value) => handleInputChange('secondaryColor', value)}
                        label='Secondary'
                      />
                    </div>
                  </div>

                  {(isEdit && !clearImgToData && logoEdit && (
                    <div className='mb-4 flex items-center gap-x-6'>
                      <div className='h-36 w-36'>
                        <img
                          src={logoEdit}
                          alt='Uploaded'
                          className='h-full w-full object-contain'
                        />
                      </div>

                      <button
                        type='button'
                        onClick={() => setClearImgToData(true)}
                        className='focus:shadow-outline rounded-lg bg-gray-300 p-2.5 font-bold text-white hover:bg-gray-500
                          focus:outline-none'>
                        <TrashSVG className='h-5 w-5 fill-white' />
                      </button>
                    </div>
                  )) || (
                      <CustomInput
                        label='Upload your logo'
                        name='logo'
                        type='file'
                        reset={resetField}
                        clearErrors={clearErrors}
                        control={control}
                        setError={setError}
                        placeholder='Drag and drop here'
                        onChangeCallback={(value) => handleInputChange('logo', value)}
                      />
                    )}

                  <div className='relative'>

                    <LocationAdditional
                      locations={locations}
                      name='location'
                      onClick={handleClearAddress}
                      addLocations={handleAddLocation}
                      errors={errors.location}
                      control={control}
                    />

                    {isError && (
                      <div
                        className={clsx('opacity-0 transition-opacity duration-300', {
                          'opacity-100': isError,
                        })}>
                        {/* TODO - This cost should come from stripe pricing somehow */}
                        <span className='text-csm font-medium italic text-orange-500'>
                          Need to add more locations? No problem! You get 1 location and each
                          additional location is just ${LOCATION.LOCATION_COST}
                        </span>
                      </div>
                    )}
                  </div>
                  <TwLableForm>Review and confirm</TwLableForm>

                  <div className='flex justify-start gap-x-4'>
                    <ButtonIcon
                      type='button'
                      disabled={isCreating}
                      onClick={handleSubmitTest}
                      content={isEdit ? 'Update my Card' : 'Test my Card'}
                      className='min-w-28 border border-light-primary transition-colors duration-150 hover:bg-light-primary
                        hover:text-white'
                    />
                    {!tokenCardConfig?.isLaunched && (
                      <ButtonIcon
                        type='button'
                        onClick={async () => {
                          queryParams.set('isLaunch', 'true');
                          window.history.replaceState(null, '', newUrlReplaced);
                          await onSubmit()
                        }}
                        content='Launch'
                        className='min-w-28 bg-light-primary text-white hover:brightness-110'
                      />
                    )}

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </TwMainGridWrapper>
    </section>
  )
}

export default ProgramCreate
