import { TwCardContainer } from 'components/modules/twComponent/twCard.module'
import { ReactComponent as HomeSVG } from 'icons/plus_circle.svg'

const AddMerchantCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className='block lg:hidden'>
      <TwCardContainer className='flex min-h-36 w-36 min-w-36 items-center justify-center border bg-white xl:hover:cursor-pointer'>
        <div onClick={onClick}>
          <HomeSVG className='h-7 w-7' />
        </div>
      </TwCardContainer>
    </div>
  )
}
export default AddMerchantCard
