import MbStickyFooter from 'components/footer/MbStickyFooter'
import Header from 'components/header'
import { LayoutProps } from './types'

// const Wrapper = tw.div`bg-white text-emerald-400`

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className='h-full'>
      <Header />
      <div className='pb-28 h-full overflow-y-auto'>
        {children}
      </div>
      <MbStickyFooter />
    </div>
  )
}

export default Layout
