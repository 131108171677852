import { useUserTeamsStore } from 'UseMerchantStore'
import clsx from 'clsx'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { MENU_BOTTOM_TYPE } from 'constants/common'
import { PATH } from 'constants/routes'
import { ReactComponent as HistorySVG } from 'icons/history.svg'
import { ReactComponent as HomeSVG } from 'icons/home.svg'
import { ReactComponent as NotificationSVG } from 'icons/notification.svg'
import { ReactComponent as QRCodeSVG } from 'icons/qr_code.svg'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useMenuBottomType } from 'stores/menuBottomType'
import tw from 'twin.macro'

export const MbStickyFooter: React.FC = (boolean) => {
  const location = useLocation()
  const firebase = useFirebase(firebaseConfig)
  const { user } = useUserNullable({ auth: firebase.auth })
  const userTeams = useUserTeamsStore(user, firebase.firebaseDb)
  const isMerchant = userTeams.type === 'SUCCESS' ? userTeams.teams?.permissions : undefined
  const isHomePage = useMemo(() => location.pathname === '/' || location.pathname.split('/').includes('admin'), [location.pathname])
  const isAdminScreen = location.pathname.includes('admin')
  const { menuBottomType, setMenuBottomType } = useMenuBottomType()

  const onClickGotoMerchant = () => {
    if (!isMerchant) return navigate(PATH.MERCHANT_CREATE)

    localStorage.setItem('isNotification', JSON.stringify(true))
    return isMerchant ?
      navigate(PATH.MERCHANT_DASHBOARD.replace(':id', Object.keys(isMerchant)[0]))
      : null
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === PATH.HOME) {
      setMenuBottomType(MENU_BOTTOM_TYPE.HOME)
    }
  }, [location.pathname, setMenuBottomType])

  if (!user) {
    return <></>
  }

  return (
    <aside className={`fixed bottom-[-1px] z-20 w-full divide-y bg-light-mb-main shadow-top lg:hidden rounded-tl-3xl rounded-tr-3xl ${isHomePage ? 'block': 'hidden'}`}>
      <div className='flex text-cxs md:text-xs py-1'>
        {isAdminScreen && (
          <TwBtmMenuItem onClick={() => { onClickGotoMerchant(); setMenuBottomType(MENU_BOTTOM_TYPE.HOME) }}
            className={clsx({
              '!text-black': menuBottomType === MENU_BOTTOM_TYPE.HOME
            })}>
            <HomeSVG className='h-5 w-5' />
            <TwBtmMenuText>Home</TwBtmMenuText>
          </TwBtmMenuItem>
        )}

        {isAdminScreen && (
          <TwBtmMenuItem onClick={() => {
            onClickGotoMerchant(); setMenuBottomType(MENU_BOTTOM_TYPE.NOTIFICATION)
          }}
            className={clsx({
              '!text-black': menuBottomType === MENU_BOTTOM_TYPE.NOTIFICATION
            })}>
            <NotificationSVG className='h-5 w-5' />
            <TwBtmMenuText>Notification</TwBtmMenuText>
          </TwBtmMenuItem>
        ) || <TwBtmMenuItem
          className={clsx({
            '!text-black': menuBottomType === MENU_BOTTOM_TYPE.HISTORY
          })}>
            <HistorySVG className='h-5 w-5' />
            <TwBtmMenuText>My History</TwBtmMenuText>
          </TwBtmMenuItem>}

        {!isAdminScreen && (
          <TwBtmMenuItem
            onClick={() => {
              localStorage.setItem('isNotification', '')
              navigate(PATH.HOME)
            }}
            className={clsx({ '!text-black': location.pathname === PATH.HOME })}>
            <HomeSVG className='h-5 w-5' />
            <TwBtmMenuText>My Cards</TwBtmMenuText>
          </TwBtmMenuItem>
        )}
        {isAdminScreen && (
          <TwBtmMenuItem onClick={() => {
            onClickGotoMerchant(); setMenuBottomType(MENU_BOTTOM_TYPE.QR_CODE)
          }}
            className={clsx({ '!text-black':menuBottomType === MENU_BOTTOM_TYPE.QR_CODE })}>
            <QRCodeSVG className='h-5 w-5' />
            <TwBtmMenuText>QR Code</TwBtmMenuText>
          </TwBtmMenuItem>
        )}

      </div>
    </aside>
  )
}
export default MbStickyFooter
export const TwBtmMenuItem = tw.div`py-1.5 gap-1 text-light-disabled md:py-3 flex cursor-pointer drop-shadow-2xl flex-1 flex-col place-content-center items-center`
export const TwBtmMenuText = tw.div`whitespace-nowrap text-xs font-medium`
