import { useUserTeamsStore } from 'UseMerchantStore'
import { WIDTH_BOUNDARY, useWindowDimensions } from 'UseWindowDimensions'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { cn } from 'components/common/utils/tailwindUtils'
import MerchantAdressQRCard from 'components/modules/MerchantAdressQRCard'
import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { PATH } from 'constants/routes'
import { useMerchantData } from 'hooks/useMerchantData'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import NotificationPanel from 'ui/NotificationPanel'
import { TokenCardConfig } from '../../../../functions/src/data/common'
import ProgramCard from '../../Program/ProgramCard'
import { useMenuBottomType } from 'stores/menuBottomType'
import { MENU_BOTTOM_TYPE } from 'constants/common'

const DashboardMerchant = () => {
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const { id: merchantIdParams } = useParams()
  const [selectedCardConfigId, setSelectedCardConfigId] = useState<string>()
  const windowDimensions = useWindowDimensions()
  const { menuBottomType } = useMenuBottomType()


  const { user } = useUserNullable({ auth: firebase.auth })
  const userTeams = useUserTeamsStore(user, firebaseDb)

  useEffect(() => {
    if (windowDimensions.width > WIDTH_BOUNDARY) {
      localStorage.setItem('isNotification', '')
    }
  }, [windowDimensions])

  const merchantId = useMemo(() => {
    if (userTeams.type === 'SUCCESS' && userTeams.teams) {
      return Object.keys(userTeams.teams.permissions).find(
        (merchantId) => merchantId === merchantIdParams,
      )
    }
    return undefined
  }, [userTeams, merchantIdParams])

  // Get merchant data from merchant table
  const { merchant } = useMerchantData(merchantId) || {}

  const { cardConfigId, cardConfig } = useMemo<{
    cardConfigId?: string
    cardConfig?: TokenCardConfig
  }>(
    () =>
      (merchant?.cardConfigs &&
        Object.entries(merchant.cardConfigs)
          .mapNotNull(([cardConfigId, cardConfig]) =>
            cardConfig.cardConfigType === 'tokens' ? { cardConfigId, cardConfig } : null,
          )
          .firstOrNull()) ??
      {},
    [merchant?.cardConfigs],
  )

  const addressStores = (!cardConfig?.isLaunched && cardConfig?.locations && Array(cardConfig?.locations).length > 0 && cardConfig?.locations.slice(0, 3)) || cardConfig?.locations

  useEffect(() => {
    if (cardConfigId) setSelectedCardConfigId(cardConfigId)
  }, [cardConfigId])

  if (!userTeams.teams || !user) {
    return <></>
  }

  if (!merchant) {
    return <SkeletonHome />
  }

  return (
    <section id="scroll-section" className='layout h-full max-w-[31.25rem]  bg-light-mb-main md:h-[calc(100vh-5.5rem)]'>
      <TwMainGridWrapper className='h-full'>
        <NotificationPanel
          user={user}
          firebaseDb={firebaseDb}
          merchantId={merchantId}
          className={cn(
            'col-span-12 hidden md:col-span-6 lg:col-span-3 lg:block',
            menuBottomType === MENU_BOTTOM_TYPE.NOTIFICATION && 'block',
          )}
        />
        <ProgramCard
          className={cn(
            'col-span-12 md:col-span-7 lg:col-span-5 lg:block',
            menuBottomType !== MENU_BOTTOM_TYPE.HOME && 'hidden',
          )}
          cardConfig={cardConfig}
          merchant={merchant}
          merchantId={merchantId}
          mode={!cardConfig ? 'create' : 'preview'}
        />

        {selectedCardConfigId && merchantId && (
          <MerchantAdressQRCard
            className={cn(
              'col-span-12 md:col-span-5 lg:col-span-4 lg:block',
              menuBottomType !== MENU_BOTTOM_TYPE.QR_CODE && 'hidden',
            )}
            cardConfigId={selectedCardConfigId}
            merchantId={merchantId}
            firebase={firebase}
            user={user}
            addressStore={addressStores}
            isLaunched={cardConfig?.isLaunched}
          />
        )}
      </TwMainGridWrapper>
    </section>
  )
}
export default DashboardMerchant
