import { MerchantState } from "UseMerchantStore"
import { useMemo } from "react"
import { TokenTemplate } from "../../functions/src/data/common"
import { ICard } from "types/card.module"

const useMerchantCardConfigs = (merchantStore: MerchantState) => {
  const templateCard = useMemo(() => {
    if (merchantStore.type === 'SUCCESS') {
      const merchant = merchantStore.merchantData
      if (!merchant.defaultCardConfigId) return null
      const cardConfig = merchant.cardConfigs?.[merchant.defaultCardConfigId]
      if (!cardConfig) return null
      const template = cardConfig.templates[cardConfig.defaultTemplateId] as TokenTemplate
      return {
        name: cardConfig.title || undefined,
        logo: merchant.logo,
        primaryColor: template?.primaryColor || undefined,
        secondaryColor: template?.secondaryColor || undefined,
        points: template?.lineMax ? Array.from({ length: template?.lineMax }, (_, i) => i + 1) : [],
        isCreate: false, // Add the isCreate property with a value of false
      } satisfies ICard
    }
    return null
  }, [merchantStore])

  const cardConfigId = useMemo(() => {
    return merchantStore.type === 'SUCCESS' ? merchantStore.merchantData.defaultCardConfigId : null
  }, [merchantStore])

  const tokenCardConfig = useMemo(() => {
    return merchantStore.type === 'SUCCESS' && merchantStore.merchantData.defaultCardConfigId ? merchantStore.merchantData.cardConfigs?.[merchantStore.merchantData.defaultCardConfigId] : null
  }, [merchantStore])

  return { templateCard, tokenCardConfig, cardConfigId }
}

export default useMerchantCardConfigs