import { Image } from '@chakra-ui/react'
import { TwCardContainer } from 'components/modules/twComponent/twCard.module'
import { ICard } from 'types/card.module'

const MerchantCard: React.FC<{ cardConfig: ICard; onClick: () => void }> = ({
  cardConfig,
  onClick,
}) => {
  return (
    <TwCardContainer
      onClick={onClick}
      style={{ background: `${cardConfig.primaryColor}` }}
      className='min-h-36 w-36 min-w-36 border xl:hover:cursor-pointer'>
      <div className='h-18'>
        {cardConfig.logo && (
          <Image
            src={cardConfig.logo}
            objectFit={'contain'}
            height={'100%'}
          />
        )}
      </div>

      <span className='line-clamp-2 text-csm font-medium'>{cardConfig.name}</span>
    </TwCardContainer>
  )
}
export default MerchantCard
