import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { useMerchantStore } from 'UseMerchantStore'
import MerchantCard from 'components/card/MerchantCard'
import { useMemo } from 'react'
import { ICard, LiveMerchantCardProps } from 'types/card.module'

const LiveMerchantCard: React.FC<LiveMerchantCardProps> = ({
  merchantId,
  firebaseDb,
  onClick,
  cardId,
}) => {
  const state = useLoyaltyCardStore(cardId, firebaseDb)
  const merchantState = useMerchantStore(merchantId, firebaseDb)
  const cardEntry = state.type === 'SUCCESS' ? state.cardData : null


  const cardConfig = useMemo(() => {
    if (!cardEntry) return null

    const template = cardEntry.balance?.[cardEntry.templateId];
    if (!template) return null;

    const points =
      (template && template.templateType === 'tokens' &&
        template.lineMax &&
        Array.from({ length: template.lineMax as number }, (_, i) => i + 1)) ||
      []

    return {
      logo: cardEntry.logo,
      name: cardEntry.title || undefined,
      primaryColor: template?.primaryColor || undefined,
      secondaryColor: template?.secondaryColor || undefined,
      isCreate: false,
      points: points,
      marked: template?.templateType === 'tokens' ? template.marked : undefined,
    } satisfies ICard
  }, [cardEntry])

  if (!cardConfig && merchantState.type === 'PENDING') {
    return <div>Loading...</div>
  }

  if (!cardConfig) {
    return <div>Merchant not found</div>
  }

  return (<div>
    <MerchantCard
      onClick={() => onClick && onClick({ merchantId, cardId })}
      cardConfig={cardConfig}
    />
  </div>
  )
}
export default LiveMerchantCard
