import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useUserTeamsStore } from 'UseMerchantStore'
import PoweredBy from 'components/PowerBy'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { PATH } from 'constants/routes'
import { ReactComponent as UserCircleSVG } from 'icons/account_circle.svg'
import React, { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'

// Interface for menu items
interface MenuItem {
  name: string
  link: string
}

// Props for Drawer component
interface ProfileSettingsProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  menuItems?: MenuItem[]
  children?: ReactNode
}

interface SettingOptionProps {
  icon?: React.ReactNode
  label: string
  onClick?: () => void
}

const SettingOption: React.FC<SettingOptionProps> = ({ icon, label, onClick }) => (
  <div
    className='mb-2 flex cursor-pointer items-center justify-between rounded-lg bg-drawer-item p-4 focus:rounded-lg
      focus-visible:rounded-lg'
    onClick={onClick}>
    <div className='flex items-center'>
      {icon}
      <span className='ml-4 font-medium text-black'>{label}</span>
    </div>
    <ChevronRightIcon
      color={'#000'}
      height={20}
      width={20}
    />
  </div>
)

const ProfileSettings: React.FC<ProfileSettingsProps> = ({ isOpen, onClose, children }) => {
  const navigate = useNavigate()
  const firebase = useFirebase(firebaseConfig)
  const { user } = useUserNullable({ auth: firebase.auth })
  const userTeams = useUserTeamsStore(user, firebase.firebaseDb)
  const isMerchant = userTeams.type === 'SUCCESS' ? userTeams.teams?.permissions : undefined
  const onFocusAdminScreen = ['admin', 'merchant'].some((path) => location.pathname.includes(path))

  const onClickGotoMerchant = async () => {
    onClose()
    if (onFocusAdminScreen) {
      return navigate(PATH.HOME)
    }

    if (!isMerchant) return navigate(PATH.MERCHANT_CREATE)

    return navigate(PATH.MERCHANT_DASHBOARD.replace(':id', Object.keys(isMerchant)[0]))
  }

  const onClickLogout = async () => {
    await firebase.auth.signOut()
  }

  return (
    <>
      <div
        className={`fixed right-0 top-0 z-30 flex h-full w-full transform flex-col bg-[#F7F9F7] text-white ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <div className='relative flex min-h-52 items-center justify-center bg-[#ded2c5]'>
          <div className='absolute left-4 top-4'>
            <button
              className='text-white'
              onClick={onClose}>
              <ChevronLeftIcon
                height={34}
                width={34}
                color={'black'}
              />
            </button>
          </div>

          <div className='text-center'>
            <div className='flex items-center justify-center'>
              {/* Avatar Placeholder */}
              <UserCircleSVG
                width={64}
                height={64}
              />
            </div>
            {(!user?.displayName && (
              <button className='mt-2 rounded-lg bg-[#BF997E] px-4 py-2 text-white'>Login</button>
            )) || <h3 className='text-xl font-semibold text-black'>{user?.displayName}</h3>}
          </div>
        </div>
        <div className='flex h-full flex-col justify-between'>
          <div className='mt-4 px-4'>
            {user?.displayName && (
              <>
                <SettingOption
                  label={isMerchant ? onFocusAdminScreen ? 'Customer' : 'Merchant'  : 'Create Merchant'}
                  onClick={onClickGotoMerchant}
                />
                <SettingOption
                  label='Logout'
                  onClick={onClickLogout}
                />
              </>
            )}
            {children}
          </div>
          <PoweredBy className='mb-20' />
        </div>
      </div>
      {isOpen && (
        <div
          className='fixed inset-0 bg-black opacity-50'
          onClick={onClose}
        />
      )}
    </>
  )
}

export default ProfileSettings
