import { motion } from 'framer-motion'

export default function Transition({ children, ...props }: { children: React.ReactNode, [other: string]: any }) {
  return (
    <motion.div
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.5 }}
      {...props}>
      {children}
    </motion.div>
  )
}
