import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { useQueryString } from 'components/QueryString'
import { useFirebase } from 'components/common/Firebase'
import InputField from 'components/input'
import { TwButton, TwLableForm } from 'components/modules/twComponent/twCommon.module'
import { TwTitle } from 'pages/Home/twHome.module'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { firebaseConfig } from 'services/FirebaseConfig'
import { TwContent } from '../twSignIn.module'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

interface IFormInput {
  email: string
}
const SignInForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>()

  const [redirectLocation] = useQueryString('redirect', '/')
  const { auth: loyaltyAuth } = useFirebase(firebaseConfig)
  const [isLinkSent, setIsLinkSent] = useState<boolean>(false)

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      await loyaltyAuth.sendSignInEmail(data.email.toString(), redirectLocation)
      setIsLinkSent(true)
    } catch (error) {
      console.error('Error sending sign-in email:', error)
    }
  }

  return (
    <div className='px-6'>
      {(isLinkSent && (
        <div className='text-center'>
          <div className='space-y-3 text-center'>
            <TwTitle>Link Sent</TwTitle>
            <TwContent className='text-sm'>
              <a
                className='!text-light-primary hover:underline'
                target={'_blank'}
                rel='noreferrer'
                href='https://mail.google.com/'>
                We&apos;ve sent a link to your email.
              </a>{' '}
              You&apos;ll need to open the link in the email continue.
            </TwContent>
          </div>
          <div className='mt-6 text-sm font-medium hover:cursor-pointer'>Change another email</div>
        </div>
      )) || (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-6 flex items-center justify-center lg:hidden'>
            <Logo height={25} />
          </div>
          <TwTitle>Welcome Back</TwTitle>
          <TwContent className=''>
            Log in to create rewards that keep
            <br /> your customers coming back
          </TwContent>
          <div className='mb-2 space-y-2'>
            <TwLableForm>EMAIL</TwLableForm>
            <InputField
              name='email'
              type='email'
              placeholder='Support@sallyshairandnail.com'
              register={register}
              errors={errors.email?.message}
              validation={{
                required: 'Email is required',
                pattern: { value: /^\S+@\S+$/i, message: 'Invalid email format' },
              }}
            />
          </div>
          <p className='mb-8 text-sm text-gray-600'>Having trouble signing in?</p>
          <TwButton type='submit'>Sign In Via Email</TwButton>
        </form>
      )}
    </div>
  )
}

export default SignInForm

