import { Button } from '@chakra-ui/react'
import { useMerchantStore } from 'UseMerchantStore'
import { useUserCardsStore } from 'UseUserCardsStore'
import clsx from 'clsx'
import { useQueryString } from 'components/QueryString'
import Transition from 'components/Transition'
import Card from 'components/card/Card'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import InputField from 'components/input'
import { TwLableForm } from 'components/modules/twComponent/twLayout.module'
import { PATH } from 'constants/routes'
import { updateProfile } from 'firebase/auth'
import useMerchantCardConfigs from 'hooks/useMerchantCardConfigs'
import { ReactComponent as LogoWhite } from 'icons/logo-white.svg'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { PendingScanLog } from '../../../../functions/src/data/common'

interface IFormInput {
  name: string
}

const MerchantAfterScan: React.FC = () => {
  const { id: merchantId } = useParams<{ id: string }>()
  const [instorescan] = useQueryString('instorescan', undefined)
  const navigate = useNavigate()
  const firebase = useFirebase(firebaseConfig)
  const { user } = useUserNullable({ auth: firebase.auth })
  const merchantStore = useMerchantStore(merchantId, firebase.firebaseDb)
  const userCardStore = useUserCardsStore(user, firebase.firebaseDb)
  const { templateCard, tokenCardConfig, cardConfigId } = useMerchantCardConfigs(merchantStore)
  const { register, handleSubmit, formState: { errors }, setError, setValue } = useForm<IFormInput>()
  const [creating, setCreating] = useState<boolean>(false)
  const [canCreateCard, setCanCreateCard] = useState<boolean>(false)


  const userCardCollection = useMemo(() => userCardStore.type === 'SUCCESS' && merchantId ? userCardStore.cardsData[merchantId] : undefined, [userCardStore, merchantId])
  const isUserHasCard = useMemo(() => userCardCollection && tokenCardConfig && userCardCollection[tokenCardConfig.defaultTemplateId], [userCardCollection, tokenCardConfig])
  const colourPrimary = useMemo(() => {
    if (merchantStore.type === 'SUCCESS') {
      const merchant = merchantStore.merchantData
      const cardConfig = merchant.cardConfigs ? merchant.cardConfigs[merchant?.defaultCardConfigId] : null
      const template = cardConfig?.templates ? cardConfig.templates[cardConfig.defaultTemplateId] : null
      return template?.primaryColor
    }
    return '#bf997f'
  }, [merchantStore])

  const onSubmit = handleSubmit(async (data) => {
    if (data.name === '') {
      setError('name', { message: 'Name is required!' })
      return
    }

    setCreating(true)
    try {
      let currentUser = user
      const name = data.name

      if (!currentUser) {
        currentUser = (await firebase.auth.signInAnonymously()).user
      }

      if (currentUser && merchantStore.type === 'SUCCESS' && cardConfigId) {
        const hydratedUser = await firebase.auth.hydrateProfileIfRequired(currentUser)

        await merchantStore.addCard(currentUser, name, cardConfigId)

        if (currentUser.displayName !== name) {  // update user display name
          await firebase.firebaseDb.getRef(`users/${currentUser.uid}/name`).set(name)
          await updateProfile(hydratedUser, { displayName: name, })
        }

      }
    } finally {
      setCreating(false)
      setCanCreateCard(true)
    }
  })

  //todo this functional is using triggerByUid to log actions
  useEffect(() => {
    if (instorescan && merchantId && user) {
      firebase.firebaseDb
        .getRef('/pendingScanLog')
        .push({ merchantId: merchantId, triggeredByUid: user.uid } satisfies PendingScanLog)
        .then(() => {
          const url = new URL(location.toString())
          url.searchParams.delete('instorescan')
          navigate(url.pathname + url.search, { replace: true })
        })
    }
    if (user && user.displayName) {
      setValue('name', user.displayName)
    }
  }, [instorescan, navigate, merchantId, user, firebase.firebaseDb, setValue])

  // Render loading, error, or main content
  if (merchantStore.type === 'PENDING') {
    return <div className='text-center'>Loading....</div> //todo add the skeleton loader
  }

  if (merchantStore.type === 'FAIL') {
    return <div className='text-center'>Data error</div>
  }

  if (merchantStore.type === 'MISSING') {
    return <div className='text-center pt-10 space-y-2'>
      <div className='text-xl'>Merchant not found</div>
      <Button className='underline italic' onClick={() => navigate(PATH.HOME)}>Go back to Home</Button>
    </div>
  }


  return (
    <section className='mx-auto w-full max-w-[31.25rem] bg-light-mb-main p-2.5 h-[calc(100vh-72px)] overflow-y-auto'>
      <div className="flex flex-col justify-between items-center h-full">
        <div className='space-y-12 w-full'>
          <div className='space-y-8'>
            <div className='mt-6 space-y-2.5'>
              <p className='text-xl font-medium'>{merchantStore.merchantData.name}</p>
              <p className='text-sm text-light-secondary'>Join our rewards program today</p>
            </div>
            {!canCreateCard && templateCard && <Card {...templateCard} isHaveData={!!templateCard} />}

          </div>
          {!canCreateCard && <div className="mt-8 flex flex-col items-center p-4 justify-center gap-4 bg-white rounded-xl shadow-md">
            <p>Tell us who owns this card</p>
            <div className='w-full text-center'>
              <TwLableForm className='text-light-secondary'>NAME</TwLableForm>
              <InputField register={register} name="name" errors={errors.name?.message} placeholder='Enter your name' className="text-center" />
            </div>
            <Button
              onClick={onSubmit}
              aria-disabled={creating || !!isUserHasCard}
              style={{ backgroundColor: colourPrimary || undefined }}
              className='px-12 py-1.5 rounded-md text-csm shadow-md bg-button-primary font-medium focus:ring-button-primary'
              disabled={creating || !!isUserHasCard}>
              {(creating && 'Getting card...') || 'Get card'}
            </Button>
          </div> || (
              <div className="space-y-6">
                <Transition>
                  <div style={{ borderColor: colourPrimary || undefined }} className='border border-cyan-300 w-min m-auto rounded-lg p-1'>
                    <div style={{ backgroundColor: colourPrimary || undefined }} className='bg-button-primary rounded-lg w-32 h-32 m-auto'>
                      <img src='/images/like.png' alt='like' className='w-32 h-32' />
                    </div>
                  </div>
                </Transition>
                <div className='flex flex-col justify-center items-center'>
                  <span>Wellcome!</span>
                  <span>Your card is all set up</span>
                </div>
              </div>
            )
          }
        </div>

        {canCreateCard && <div className="flex flex-col justify-center items-center">
          <Button
            onClick={() => navigate(PATH.HOME)}
            style={{ backgroundColor: colourPrimary || undefined }}
            className='px-12 py-1.5 rounded-md text-csm shadow-md bg-button-primary font-medium focus:ring-button-primary mt-24'>
            Go to card
          </Button>
        </div>
        }
        <div className={clsx('flex flex-col justify-center items-center', { 'mb-12': !!user, 'mb-6': !user })}>
          <span>Powered by</span>
          <div className='bg-light-primary p-1 px-2.5'>
            <div className='max-w-24'><LogoWhite className='w-full' /></div>
          </div>
        </div>
      </div>

    </section>
  )
}
export default MerchantAfterScan

