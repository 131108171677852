import { Button } from '@chakra-ui/react'
import { ButtonIcon } from 'components/button/ButtonIcon'
import Card from 'components/card/Card'
import { cn } from 'components/common/utils/tailwindUtils'
import { PATH } from 'constants/routes'
import { ReactComponent as ShoppingCardSVG } from 'icons/shopping-cart.svg'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useProgramStore } from 'stores/programStore'
import { MerchantEntry, TokenCardConfig } from '../../../functions/src/data/common'

interface IProgramCard {
  merchant?: MerchantEntry
  mode: 'create' | 'edit' | 'preview',
  isPayment?: boolean
  merchantId?: string
  cardConfig: TokenCardConfig | undefined
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
}

const ProgramCard: React.FC<IProgramCard> = ({
  merchant,
  cardConfig,
  className,
  merchantId,
  isPayment,
  mode
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { programBody, setProgramBody } = useProgramStore()
  const {
    name: nameBody,
    primaryColor: primaryColorBody,
    secondaryColor: secondaryColorBody,
    logo: logoBody,
    reward,
  } = programBody

  const { isLaunched, templates } = cardConfig ?? {}

  const {
    lineMax,
    primaryColor,
    secondaryColor,
    trial,
    target: count,
  } = (templates && cardConfig && templates[cardConfig?.defaultTemplateId]) || {}

  const isCardConfigAvailable = useMemo(() => !!cardConfig, [cardConfig]);

  const points = useMemo(() => {
    return Array.from({ length: reward ?? lineMax ?? 0 }, (_, i) => i + 1);
  }, [reward, lineMax]);

  const onClickToCreate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    navigate(PATH.PROGRAM_CREATE)
  }

  const handleGoPayment = useCallback(
    () => merchantId && navigate(PATH.PROGRAM_PAYMENT.replace(':merchantId', merchantId)),
    [navigate, merchantId]
  );

  const handleEditNavigate = useCallback(
    () => merchantId && navigate(PATH.PROGRAM_EDIT.replace(':id', merchantId)),
    [navigate, merchantId]
  );

  const renderStatusBadge = useMemo(() => {
    if (isLaunched && merchantId && (mode === 'preview' || isCardConfigAvailable)) {
      return (
        <div className='flex items-center gap-x-2 rounded-xl bg-[#c1ff72] py-0.5 px-6 text-sm'>
          <p>Active</p>
        </div>
      );
    } else if (merchantId) {
      return (
        <div className='flex items-center gap-x-2 rounded-xl bg-yellow-400 px-1'>
          <ShoppingCardSVG className='h-5 w-5' />
          <p>Inactive</p>
        </div>
      );
    }
    return null;
  }, [isLaunched, merchantId, mode, isCardConfigAvailable]);

  const renderLaunchSection = useMemo(
    () => (
      <div className='space-y-4 md:col-span-2'>
        <p className='text-light-secondary'>Your program is not active.</p>
        <p>Launch your program today to start rewarding your customers</p>
        <div className='flex justify-start gap-x-4'>
          <ButtonIcon type='submit' content='Test' className='min-w-28 border border-light-primary hover:bg-light-primary hover:text-white' />
          {!isPayment && (
            <ButtonIcon
              type='button'
              content='Launch'
              onClick={handleGoPayment}
              className='min-w-28 bg-light-primary text-white hover:brightness-110'
            />
          )}
        </div>
        {trial !== null && count !== null && (
          <p className='text-sm'>
            <span className='rounded-xl bg-yellow-400 p-1 px-2 text-sm'>{`${trial}/${count}`}</span> Test cards left
          </p>
        )}
      </div>
    ),
    [isPayment, handleGoPayment, trial, count]
  );

  const renderActiveSection = useMemo(
    () => (
      <>
        <div>
          <div className='my-10'>
            <p className='text-sm'>
              <span className='rounded-xl bg-[#c1ff72] p-1 px-4 text-sm'>{`${trial}/${count}`}</span> Cards left
            </p>
          </div>
          <p className='text-[18px]'>Your digital loyalty cards are flying off the shelf! 🚀</p>
          <p className='text-[18px]'>Want peace of mind? Go Unlimited—no more running out!</p>
        </div>
        {
          !isPayment && (
            <div className='mt-4'>
              <ButtonIcon
                type='button'
                content='Go Unlimited'
                onClick={() => merchantId && navigate(PATH.PROGRAM_PAYMENT.replace(':merchantId', merchantId))}
                className='min-w-28 border hover:border-light-primary hover:text-light-primary transition-colors duration-150 hover:bg-transparent bg-light-primary
            text-white'
              />
            </div>
          )
        }
      </>
    ),
    [trial, count, isPayment, merchantId, navigate]
  );


  useEffect(() => {
    if (lineMax) {
      setProgramBody({ ...programBody, reward: lineMax })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])  // just update once when component mounted

  return (
    <div className={cn('lg:h-auto p-4 pb-6 shadow-md bg-white box-content h-min rounded-md', className)}>
      <div className='flex items-start justify-between'>
        <h2 className='mb-4 text-xl font-semibold'>Program</h2>
        {renderStatusBadge}
        {merchantId && <Button onClick={handleEditNavigate}>Edit</Button>}
      </div>
      <Card
        primaryColor={(primaryColorBody || primaryColor) as string}
        secondaryColor={(secondaryColorBody || secondaryColor) as string}
        name={(nameBody || merchant?.cardConfigs && merchant.defaultCardConfigId && merchant?.cardConfigs[merchant.defaultCardConfigId].title || undefined)}
        logo={(merchant?.logo || logoBody) as string}
        isCreate={mode === 'create'}
        onClick={onClickToCreate}
        points={points}
        isHaveData={isCardConfigAvailable}
        isPreview={mode === 'preview'}
      />
      {!isLaunched && mode === 'preview' && isCardConfigAvailable ? renderLaunchSection : null}
      {isLaunched && mode === 'preview' && isCardConfigAvailable ? renderActiveSection : null}

    </div>
  )
}
export default ProgramCard